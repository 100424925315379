import React from 'react'
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import '../../styles/live.css'
import { useEffect, useState } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';



const Live=()=> {

    const status = useSelector((state)=> state.stockData.sidebarStatus)
    const [data, setData] = useState({});

  
    useEffect(() => {
      // // Fetch data from the backend
      // axios.get('http://localhost:5000/get_stock_data')
      //   .then(response => {
      //     setData(response.data);
      //   })
      //   .catch(error => {
      //     console.error('Error fetching data:', error);
      //   });
    },[]);

  return (
    <main className='main-container'>
        <div className='main-title'>
            <h1>Live Data</h1>
        </div>
        <div className='inputs'>
        <InputGroup className="mb-3">
        <InputGroup.Text id="inputGroup-sizing-default">
          <b>Date</b>
        </InputGroup.Text>
        <Form.Control
          type='date'
          aria-label="Default"
          aria-describedby="inputGroup-sizing-default"
          onKeyUp={(e)=>setData({...data,date:e.target.value})}
        />
      </InputGroup>
      <InputGroup className="mb-3">
        <InputGroup.Text id="inputGroup-sizing-default">
          <b>From-Time</b>
        </InputGroup.Text>
        <Form.Control
          type='time'
          aria-label="Default"
          aria-describedby="inputGroup-sizing-default"
          onKeyUp={(e)=>setData({...data,from_time:e.target.value})}
        />
      </InputGroup>
      <InputGroup className="mb-3">
        <InputGroup.Text id="inputGroup-sizing-default">
        <b>To-Time</b>
        </InputGroup.Text>
        <Form.Control
          type='time'
          aria-label="Default"
          aria-describedby="inputGroup-sizing-default"
          onKeyUp={(e)=>setData({...data,to_time:e.target.value})}
        />
      </InputGroup>
        <InputGroup className="mb-3">
        <InputGroup.Text id="inputGroup-sizing-default">
          <b>Open Price</b>
        </InputGroup.Text>
        <Form.Control
          aria-label="Default"
          aria-describedby="inputGroup-sizing-default"
          onKeyUp={(e)=>setData({...data,open_price:e.target.value})}
        />
      </InputGroup>
      <InputGroup className="mb-3">
        <InputGroup.Text id="inputGroup-sizing-default">
          <b>Volume</b>
        </InputGroup.Text>
        <Form.Control
          aria-label="Default"
          aria-describedby="inputGroup-sizing-default"
          onKeyUp={(e)=>setData({...data,volume:e.target.value})}
        />
      </InputGroup>
      <InputGroup className="mb-3">
        <InputGroup.Text id="inputGroup-sizing-default">
          <b>Average</b>
        </InputGroup.Text>
        <Form.Control
          aria-label="Default"
          aria-describedby="inputGroup-sizing-default"
          onKeyUp={(e)=>setData({...data,average:e.target.value})}
        />
      </InputGroup>
      <InputGroup className="mb-3">
        <InputGroup.Text id="inputGroup-sizing-default">
          <b>Profit</b>
        </InputGroup.Text>
        <Form.Control
          aria-label="Default"
          aria-describedby="inputGroup-sizing-default"
          onKeyUp={(e)=>setData({...data,profit:e.target.value})}
        />
      </InputGroup> <InputGroup className="mb-3">
        <InputGroup.Text id="inputGroup-sizing-default">
          <b>Loss</b>
        </InputGroup.Text>
        <Form.Control
          aria-label="Default"
          aria-describedby="inputGroup-sizing-default"
          onKeyUp={(e)=>setData({...data,loss:e.target.value})}
        />
      </InputGroup>
      <Button type='button' variant='danger' style={{marginBottom:'15px'}}>Submit</Button>
        </div><br></br>
        <Table striped bordered hover variant="dark">
      <thead>
        <tr>
          <th>Company Name</th>
          <th>Open Price</th>
          <th>Entry Price</th>
          <th>Entry Time</th>
          <th>Profit 1%</th>
          <th>Acheived Time</th>
          <th>Stop Loss</th>
          <th>Loss Time</th>
          <th>Closing Price</th>
          <th>Closing Time</th>
          <th>Entry Volume</th>
          <th>Yesterday Volume</th>
          <th>Average</th>


        </tr>
      </thead>
      <tbody>
        {/* {data.map((data)=>{
          return (
            <tr>
              <td>{data.tkr}</td>
              <td>{data.td}</td>
            </tr>
          )
        })} */}
      </tbody>
    </Table>
    </main>
  )
}

export default Live